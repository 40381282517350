import { useContext, useEffect, useState } from 'react'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import Loader from '../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { LottieWrapper, SpinnerWrapper, ToastWrapper } from './styles'
import { Toast } from '../../components/toast/Toast'
import ToastContext from '../../services/toast/ToastContext'

const Interceptor = () => {
  const { toastOpen, toastMessage, setToast } = useContext(ToastContext)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const requestStack: number[] = []
    const onRequest = (config: AxiosRequestConfig): any => {
      requestStack.push(requestStack.length + 1)
      requestStack.length === 1 && setLoading(true)
      config.headers = { ...config.headers, 'Access-Control-Allow-Origin': '*' }
      return config
    }

    const onRequestError = (error: AxiosError): Promise<AxiosError> => {
      requestStack.pop()
      requestStack.length === 0 && setLoading(false)
      // setToast(false, error.message)
      return Promise.reject(error)
    }

    const onResponse = (response: AxiosResponse): AxiosResponse => {
      requestStack.pop()
      requestStack.length === 0 && setLoading(false)
      return response
    }

    const onResponseError = (error: AxiosError): Promise<AxiosError> => {
      requestStack.pop()
      requestStack.length === 0 && setLoading(false)
      // setToast(false, error.message)
      return Promise.reject(error)
    }
    axios.interceptors.request.use(onRequest, onRequestError)
    axios.interceptors.response.use(onResponse, onResponseError)
  }, [setToast])

  return (
    <>
      {isLoading && (
        <SpinnerWrapper>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
        </SpinnerWrapper>
      )}
      {toastOpen !== null && (
        <ToastWrapper>
          <Toast
            text={toastMessage}
            type={toastOpen ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setToast(status ? status : null, '')
            }
          />
        </ToastWrapper>
      )}
    </>
  )
}

export default Interceptor
