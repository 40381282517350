import { FC, lazy, Suspense, useContext, useEffect, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import './App.css'
import { menuList } from './composites/sidebar/SidebarData'
import { GlobalStyle } from './global'
import ErrorPage from './pages/errorPage/ErrorPage'
import { AuthContext } from './services/AuthContext'
import SearchContext from './services/search/SearchContext'
import { getUserEntitlements } from './services/userVisibilityContext/UserVisibilityApi'
import { ThemeContextProvider } from './store/ThemeContext'

const HomePage = lazy(() => import('./pages/HomePage'))
const DashboardPage = lazy(() => import('./pages/DashboardPage'))

const ErrorComponent = (error: FallbackProps) => {
  return <ErrorPage error={error} />
}

const AppRoutes: FC = () => {
  const { setSelectedTab, setAccountDataList } = useContext(SearchContext)
  const { accessToken, setUserRole, loginRoles, setLoginRoles, initialLoader } =
    useContext(AuthContext)
  const [entitlementLoader, setEntitlementLoader] = useState(true)
  const navigate = useNavigate()
  const errorCode = {
    error: {
      response: {
        status: 404,
      },
    },
  }
  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.pathname = '/Statements'
      menuList[0].isClicked = true
      navigate('/Statements')
      setAccountDataList([])
    } else if (window.location.pathname === '/Accounts') {
      menuList.forEach((value) => {
        if (value.name == 'Account & Client Documents' && !value.isClicked) {
          value.isClicked = !value.isClicked
        } else if (value.name != 'Account & Client Documents')
          value.isClicked = false
      })
      menuList[1].isClicked = true
      setSelectedTab('Account & Client Documents')
      navigate('/Accounts')
      setAccountDataList([])
    } else if (window.location.pathname === '/DraftDocuments') {
      menuList.forEach((value) => {
        if (value.name == 'Draft Documents' && !value.isClicked) {
          value.isClicked = !value.isClicked
        } else if (value.name != 'Draft Documents') value.isClicked = false
      })
      menuList[2].isClicked = true
      setSelectedTab('Draft Documents')
      navigate('/DraftDocuments')
      setAccountDataList([])
    }
  }, [])

  useEffect(() => {
    setEntitlementLoader(true)
    const fetchUserEntitlement = async () => {
      try {
        const entitlementResponse = await getUserEntitlements()
        const role = entitlementResponse.data.find((role: string) =>
          role.startsWith('DM')
        )
        role && setUserRole(role)
        role && setLoginRoles({ ...loginRoles, Entitlements: [role] })
      } catch (error) {
        console.log('Error fetching user entitlement', error)
      } finally {
        setEntitlementLoader(false)
      }
    }

    accessToken && fetchUserEntitlement()
  }, [accessToken])

  return (
    <>
      <ThemeContextProvider>
        <ErrorBoundary FallbackComponent={(error) => ErrorComponent(error)}>
          <Suspense>
            <GlobalStyle />
            <Routes>
              <Route
                element={<HomePage entitlementLoader={entitlementLoader} />}
              >
                <Route path="/" element={<Navigate to="/Statements" />} />
                <Route path="/Statements" element={<DashboardPage />} />
                {loginRoles.Entitlements.includes('DM-ADMIN') && (
                  <>
                    <Route path="/Accounts" element={<DashboardPage />} />
                    <Route
                      path="/DraftDocuments"
                      element={<DashboardPage />}
                    />{' '}
                  </>
                )}
                <Route path="/*" element={<ErrorPage error={errorCode} />} />
              </Route>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </ThemeContextProvider>
    </>
  )
}
export default AppRoutes
