import { CancelToken } from 'axios'
import { GetDraftDocumentPayload, PostDraftPayload } from '../../composites/draftDocuments/draftDocumentsTab/IDraftDocumentsTab'
import {
  ResponsePropsData, ValidateAccountProps
} from '../../composites/searchDocument/ISearchDocument'
import { SubmitPendingDraftPaylodProps } from '../../composites/submitFiles/ISubmitFiles'
import appConfig from '../../settings/appConfig'
import axiosProtectedInstance from '../http/axiosService'
import ENDPOINTS from '../httpEndpoints'
import {
  AccountSearchByNumberPayloadInterface,
  AccountValidationPayloadInterface,
  ClientByIdPayloadInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  DeleteIdPayloadInterface,
  // DownloadFilePayload,
  DownloadFilePayloadInterface, DraftSubmitFilePayloadInterface, DraftSubmitFileResponsePayload,
  FileUploadResponseInterface,
  MappingInterface,
  UpdateAccountPayload,
  UserPayloadInterface
} from './ISearchContext'

const host = appConfig.apiEndPoint

export const clientSearchByNameAPI = (
  payload: ClientSearchByNamePayloadInterface
) => {
  return axiosProtectedInstance.post<ClientSearchByNameResponseInterface[]>(
    host + ENDPOINTS.GET_CLIENT_LIST_BY_NAME,
    payload
  )
}
export const accountSearchByNumberAPI = (
  payload: AccountSearchByNumberPayloadInterface
) => {
  return axiosProtectedInstance.post<[ClientSearchByNameResponseInterface]>(
    host + ENDPOINTS.GET_ACCOUNTS_BY_NUMBER,
    payload
  )
}

export const accountSearchByIdClientId = (
  payload: ClientByIdPayloadInterface
) => {
  const urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_CLIENTID
  return axiosProtectedInstance.get<ResponsePropsData>(
    host + urlEndpoint.replace('{id}', payload.id.toString())
  )
}

export const accountSearchByIdAPI = (
  payload: ClientByIdPayloadInterface,
  roleName: string
) => {
  let urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_ID

  urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_ID_OLD

  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
    urlEndpoint
      .replace('{id}', payload.id.toString())
      .replace('{mailId}', payload.mailId ? payload.mailId : '')
      .replace('{PageNumber}', payload.PageNumber.toString())
      .replace('{PageSize}', payload.PageSize.toString())
  )
}
export const documentsByDraftRecordsAPI = (body: GetDraftDocumentPayload, cancelToken: CancelToken) => {
  const urlEndpoint = ENDPOINTS.GET_DOCUMENT_DETAILS
  return axiosProtectedInstance.post<any>(host + urlEndpoint, body, { cancelToken: cancelToken })
}

export const documentsByPendingDraftRecordsAPI = (query: string, cancelToken: CancelToken) => {
  const urlEndpoint = ENDPOINTS.GET_PENDING_DRAFTS_DOCUMENT_DETAILS + query;
  return axiosProtectedInstance.post<any>(host + urlEndpoint, null, { cancelToken: cancelToken })
}

export const documentsByPendingDraftProcessRecordsAPI = (query: string, cancelToken: CancelToken) => {
  const urlEndpoint = ENDPOINTS.GET_PENDING_DRAFTS_PROCESS_DOCUMENT_DETAILS + query;
  return axiosProtectedInstance.post<any>(host + urlEndpoint, null, { cancelToken: cancelToken })
}

export const accountSearchByIdEntity = (
  payload: ClientByIdPayloadInterface
) => {
  const urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_ENTITY
  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
    urlEndpoint
      .replace('{id}', payload.id.toString())
      .replace('{PageNumber}', payload.PageNumber.toString())
      .replace('{PageSize}', payload.PageSize.toString())
  )
}

export const accountSearchByIdRepCode = (
  payload: ClientByIdPayloadInterface,
  roleName: string
) => {
  let urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_REP_CODE

  urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_REP_CODE_OLD

  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
    urlEndpoint
      .replace('{id}', payload.id.toString())
      .replace('{mailId}', payload.mailId ? payload.mailId : '')
      .replace('{PageNumber}', payload.PageNumber.toString())
      .replace('{PageSize}', payload.PageSize.toString())
  )
}

export const accountSearchByIdOfficeCode = (
  payload: ClientByIdPayloadInterface,
  roleName: string
) => {
  let urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_OFFICE_CODE

  urlEndpoint = ENDPOINTS.GET_ACCOUNT_DETAILS_BY_OFFICE_CODE_OLD

  return axiosProtectedInstance.get<ResponsePropsData>(
    host +
    urlEndpoint
      .replace('{id}', payload.id.toString())
      .replace('{mailId}', payload.mailId ? payload.mailId : '')
      .replace('{PageNumber}', payload.PageNumber.toString())
      .replace('{PageSize}', payload.PageSize.toString())
  )
}

export const getDropdownSearchAPI = () => {
  return axiosProtectedInstance.get<MappingInterface>(
    host + ENDPOINTS.GET_DROPDOWNLIST
  )
}

export const fileUploadRequest = (payload: FormData) => {
  return axiosProtectedInstance.post<FileUploadResponseInterface>(
    host + ENDPOINTS.POST_UPLOAD,
    payload
  )
}

export const getStatusSearchAPI = () => {
  return axiosProtectedInstance.get(host + ENDPOINTS.STATUS_MAPPING)
}

export const accountSearchUpdate = (payload: UpdateAccountPayload) => {
  return axiosProtectedInstance.put(host + ENDPOINTS.UPDATE_ACCOUNT_RE_INDEX, payload)
}

export const accountNumberValidation = (
  payload: AccountValidationPayloadInterface
) => {
  const urlEndpoint = ENDPOINTS.ACCOUNT_VALIDATE
  return axiosProtectedInstance.get<ValidateAccountProps>(
    host + urlEndpoint.replace('{id}', payload.id.toString())
  )
}

export const downloadFileAPI = (payload: DownloadFilePayloadInterface) => {
  const urlEndpoint = ENDPOINTS.DOWNLOAD_FILE
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Id.toString())
  )
}

export const accountDeleteByIdAPI = (payload: DeleteIdPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.DELETE_ID
  return axiosProtectedInstance.delete(
    host + urlEndpoint.replace('{id}', payload.id.toString())
  )
}

export const getUserMappingDetails = (payload: UserPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.USERS_MAPPING
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Role)
  )
}

export const getUserAccess = (payload: UserPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.USER_ACCESS_MAPPING
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Role)
  )
}

export const getUserEntitlement = (payload: UserPayloadInterface) => {
  const urlEndpoint = ENDPOINTS.USER_ENTITLEMENT_MAPPING
  return axiosProtectedInstance.get(
    host + urlEndpoint.replace('{id}', payload.Role)
  )
}

export const postDraftSubmitFile = (payload: PostDraftPayload) => {
  return axiosProtectedInstance.post<DraftSubmitFileResponsePayload>(
    host + ENDPOINTS.SUBMIT_DRAFT_DOCUMENT,
    payload
  )
}

export const postAccountSubmitFile = (
  payload: DraftSubmitFilePayloadInterface
) => {
  return axiosProtectedInstance.post<DraftSubmitFileResponsePayload>(
    host + ENDPOINTS.SUBMIT_FILE,
    payload
  )
}
export const getDocumentDetailsAPI = (body: any) => {
  // const hostAndURLEndpoint = host + ENDPOINTS.GET_DOCUMENT_DETAILS + queryString
  const hostAndURLEndpoint = host + ENDPOINTS.GET_DOCUMENT_DETAILS
  return axiosProtectedInstance.post<ResponsePropsData>(hostAndURLEndpoint, body)
}

export const deleteDocumentDetailsAPI = (query: string) => {
  const urlEndpoint = ENDPOINTS.DELETE_DOCUMENT + query
  return axiosProtectedInstance.delete(host + urlEndpoint)
}

export const submitPendingDocumentDetailsAPI = (payload: SubmitPendingDraftPaylodProps) => {
  return axiosProtectedInstance.post<any>(host + ENDPOINTS.SUBMIT_DRAFT_PENDING_DOCUMENT, payload)
}