import { AxiosError } from 'axios'
import {
  createContext,
  Dispatch,
  ReactNode, useCallback, useContext, useEffect, useMemo, useState
} from 'react'
import { apiMessages } from '../../apiConfig/apiMessages'
import { AxiosErrorMessage, DocumentFilterType, SearchTableResponseModel } from '../../composites/searchDocument/ISearchDocument'
import { AuthContext } from '../AuthContext'
import ToastContext from '../toast/ToastContext'
import { getRepAccessInfoByUserName } from '../userVisibilityContext/UserVisibilityApi'
import {
  AccountDetailsInterface,
  AccountSearchByNumberPayloadInterface,
  ClientSearchByNamePayloadInterface,
  ClientSearchByNameResponseInterface,
  MappingInterface,
  UserPayloadInterface,
  UserRoleMapping,
  VisibilityDataResponseInterface
} from './ISearchContext'
import {
  accountSearchByNumberAPI,
  clientSearchByNameAPI,
  getDocumentDetailsAPI,
  getDropdownSearchAPI,
  getUserAccess
} from './SearchAPI'

export interface SearchContextInterface {
  apiError: object | null | string
  dropdownList: MappingInterface
  accountDataList: SearchTableResponseModel[]
  accounts: AccountDetailsInterface[] | null
  setAccountDataList: Dispatch<React.SetStateAction<SearchTableResponseModel[]>>
  entityDataList: SearchTableResponseModel[]
  setEntityDataList: Dispatch<React.SetStateAction<SearchTableResponseModel[]>>
  userMappingList: UserRoleMapping
  userLoading: boolean
  searchClients: ClientSearchFn
  clients: ClientSearchByNameResponseInterface[] | null
  selectClient: SelectClientFn
  errorText: string
  setClients: Dispatch<
    React.SetStateAction<ClientSearchByNameResponseInterface[] | null>
  >
  selectedAccount: AccountDetailsInterface | null
  setAccounts: Dispatch<React.SetStateAction<AccountDetailsInterface[] | null>>
  selectAccount: SelectAccountFn
  selectedClient: ClientSearchByNameResponseInterface | null
  fetchDocumentListByFilters: FetchDocumentListByFiltersFn
  searchAccountsByNumber: AccountSearchByNumberFn
  visibilityData: any
  setVisibilityData: any
  selectedTab: string
  setSelectedTab: Dispatch<React.SetStateAction<string>>
  visibilityLoading: boolean
  loading: boolean
  setDocumentFilters: Dispatch<React.SetStateAction<DocumentFilterType>>
  documentFilters: DocumentFilterType
  uploadDialog: boolean
  setUploadDialog: Dispatch<React.SetStateAction<boolean>>
  totalRecordsCount: number
}
interface FetchDocumentListByFiltersFn {
  (payload: number): Promise<boolean | void>
}
interface AccountSearchByNumberFn {
  (payload: AccountSearchByNumberPayloadInterface): Promise<boolean | void>
}
interface SelectAccountFn {
  (account: AccountDetailsInterface | null): void
}
interface SelectClientFn {
  (client: ClientSearchByNameResponseInterface | null): void
}

interface ClientSearchFn {
  (payload: ClientSearchByNamePayloadInterface): Promise<boolean | void>
}
const SearchContext = createContext<SearchContextInterface>(
  {} as SearchContextInterface
)

interface ContextProps {
  children?: ReactNode
}

const defaultUserMapPayload = {
  // userId: '',
  // userType: '',
  // userEntitlement: [],
  entityCode: [],
  repCode: [],
  officeCode: [],
  accountnumbers: [],
}

export const SearchContextProvider = ({ children }: ContextProps) => {
  const authCtx = useContext(AuthContext);
  const { setToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState<string>('Statements')
  const [apiError, setApiError] = useState<object | null | string>(null)
  const [dropdownList, setDropdownList] = useState<MappingInterface>({
    domainList: [],
  })
  const [accountDataList, setAccountDataList] = useState<
    SearchTableResponseModel[]
  >([])
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  const [entityDataList, setEntityDataList] = useState<
    SearchTableResponseModel[]
  >([])
  const [userMappingList, setUserMappingList] = useState<UserRoleMapping>(
    defaultUserMapPayload
  )
  const [userLoading, setUserLoading] = useState(false)
  const [clients, setClients] = useState<
    ClientSearchByNameResponseInterface[] | null
  >(null)

  const [selectedClient, selectClient] =
    useState<ClientSearchByNameResponseInterface | null>(null)

  const [selectedAccount, selectAccount] =
    useState<AccountDetailsInterface | null>(null)
  const [accounts, setAccounts] = useState<AccountDetailsInterface[] | null>(
    null
  )
  const [errorText, setErrorText] = useState('')
  const [visibilityData, setVisibilityData] = useState(
    {} as VisibilityDataResponseInterface
  )
  const [visibilityLoading, setVisibilityLoading] = useState(false);
  const [documentFilters, setDocumentFilters] = useState<DocumentFilterType>({ currentPage: 1, offset: 0, limit: 50 } as DocumentFilterType);
  const [uploadDialog, setUploadDialog] = useState<boolean>(false);

  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()
    return year.concat('-').concat(month).concat('-').concat(day)
  }

  // Check wether Filter Value Exist or Not
  const hasFilterValue = () => {
    const filteredKeys = Object.keys(documentFilters)
      .filter(
        (filterKey) => filterKey !== 'fileType' && filterKey !== 'docType'
      )
      .find(
        (filter) =>
          documentFilters[filter as keyof DocumentFilterType] !== ''
      )
    return filteredKeys;
  }

  const fetchDocumentListByFilters = async (pageNum: number) => {
    if (
      authCtx?.loginRoles?.Entitlements &&
      authCtx?.loginRoles?.Entitlements?.length > 0
    ) {
      const filteredKeys = hasFilterValue();
      if (filteredKeys) {
        setLoading(true)
        try {
          const dataForSent = {
            "accountNumber": documentFilters?.accountNumber || null,
            "entityCode": documentFilters?.entityCode || null,
            "officeCode": documentFilters?.officeCode || null,
            "repCode": documentFilters?.repCode || null,
            "fileTypeId": documentFilters?.fileType || null,
            "statusId": 2,
            "clientId": null,
            "docTypeId": documentFilters?.docType || null,
            "domainId": 1,
            "documentDateFrom": documentFilters?.startDate ? formatDate(new Date(documentFilters?.startDate)) : null,
            "documentDateTo": documentFilters?.endDate ? formatDate(new Date(documentFilters?.endDate)) : null,
            "createdDateFrom": null,
            "createdDateTo": null,
            "createdBy": null,
            "pageNumber": pageNum,
            "pageSize": documentFilters.limit || 50,
          }
          const response = await getDocumentDetailsAPI(dataForSent)
          if (response?.data?.documents.length) {
            setAccountDataList(response?.data?.documents)
            setTotalRecordsCount(response?.data?.totalRecords)
            setLoading(false)
          } else if (response?.data?.documents.length === 0) {
            setLoading(false)
            setAccountDataList([])
            setTotalRecordsCount(0)
            const errorMessage = response?.data?.responseMessage
              ? response?.data?.responseMessage
              : 'No Data Available'
            setToast(false, errorMessage)
          }
        } catch (error) {
          setLoading(false)
          const getErr = error as AxiosError
          const getErrRes = getErr?.response?.data as AxiosErrorMessage
          setAccountDataList([])
          setTotalRecordsCount(0)

          setToast(
            false,
            getErrRes?.message
              ? getErrRes?.message
              : getErrRes?.detail
                ? getErrRes?.detail
                : (getErrRes && typeof (getErrRes) === "string") ? `${getErrRes}` : apiMessages.error.somethingWentWrong
          )
        }
      } else {
        setToast(
          false,
          'Please enter at least one of the following: Account Number, Office Code, Repcode, or Entity Code.'
        )
      }
    } else {
      setToast(false, 'You are not authorized to search')
    }
  }

  const searchClients = useCallback(
    async (payload: ClientSearchByNamePayloadInterface) => {
      try {
        let flag = false
        await clientSearchByNameAPI(payload)
          .then((response) => {
            if (response.data.length === 0) {
              flag = true
              setErrorText('No Results Found')
            }
            setClients(response.data)
          })
          .catch(() => {
            flag = false
            setErrorText('Please try again')
          })
        return flag
      } catch (error) {
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    },
    []
  )

  const searchAccountsByNumber = useCallback(
    async (payload: AccountSearchByNumberPayloadInterface) => {
      try {
        // setApiLoading(true)
        let flag = false
        await accountSearchByNumberAPI(payload)
          .then((response) => {
            if (
              !response.data.length ||
              !response.data[0].detailsByAccounts.length
            ) {
              flag = true
              setErrorText('No Results Found')
            }
            // setClients(response.data)
            // selectClient(response.data[0])
            setAccounts(response.data[0].detailsByAccounts)
          })
          .catch(() => {
            flag = false
            setErrorText('Please try again')
          })
        // setApiLoading(false)
        return flag
      } catch (error) {
        // setApiLoading(false)
        if (error instanceof Error) {
          setApiError(error)
          selectClient({} as ClientSearchByNameResponseInterface)
          setAccounts([])
        }
      }
    },
    []
  )

  useEffect(() => {
    async function getDropdownList() {
      try {
        const response = await getDropdownSearchAPI()
        setDropdownList(response.data)
      } catch (error) {
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    }

    async function getUserAccessList(payload: UserPayloadInterface) {
      setUserLoading(true)
      try {
        const response = await getUserAccess(payload)
        userMappingList.accountnumbers = response?.data?.accountnumbers || []
        userMappingList.entityCode = response?.data?.entityCode || []
        userMappingList.officeCode = response?.data?.officeCode || []
        userMappingList.repCode = response?.data?.repCode || []
        setUserMappingList({ ...userMappingList })
        setUserLoading(false)
      } catch (error) {
        setUserLoading(false)
        if (error instanceof Error) {
          setApiError(error)
        }
      }
    }
    // async function getUserEntitlementList(payload: UserPayloadInterface) {
    //   setUserLoading(true)
    //   try {
    //     const response = await getUserEntitlement(payload)
    //
    //     userMappingList.userEntitlement = response.data.userEntitlement
    //     userMappingList.userId = response.data.userId
    //     userMappingList.userType = response.data.userType
    //     setUserMappingList({ ...userMappingList })
    //     setUserLoading(false)
    //     if (authCtx?.userEmail) {
    //       getUserAccessList({ Role: authCtx?.userEmail })
    //     }
    //   } catch (error) {
    //     setUserLoading(false)
    //     if (error instanceof Error) {
    //       setApiError(error)
    //     }
    //   }
    // }
    if (authCtx?.accessToken) {
      getDropdownList()
      // if (
      //   authCtx?.loginRoles?.Entitlements &&
      //   authCtx?.loginRoles?.Entitlements.length > 0
      // )
      // getUserEntitlementList({
      //   Role: authCtx?.loginRoles?.Entitlements[0],

      // })
      // if (!authCtx?.loginRoles?.Entitlements.includes('DM-CORRESPONDENT')) {
      getRepAccessInfoByUserName()
        .then((res) => {
          setVisibilityData(res.data)
          setVisibilityLoading(true)
          setUserLoading(false)
        })
        .catch((err) => {
          console.log('error', err)
          setVisibilityLoading(true)
          setUserLoading(false)
        })
      // } else setUserLoading(false)
    }
  }, [authCtx?.accessToken])

  const value = useMemo(
    () => ({
      apiError,
      dropdownList,
      accountDataList,
      setAccountDataList,
      entityDataList,
      setEntityDataList,
      userMappingList,
      userLoading,
      searchClients,
      clients,
      selectClient,
      errorText,
      setClients,
      setAccounts,
      accounts,
      searchAccountsByNumber,
      fetchDocumentListByFilters,
      totalRecordsCount,
      selectAccount,
      selectedAccount,
      selectedClient,
      visibilityData,
      setVisibilityData,
      setSelectedTab,
      selectedTab,
      visibilityLoading,
      documentFilters,
      setDocumentFilters,
      loading,
      uploadDialog,
      setUploadDialog
    }),
    [
      apiError,
      dropdownList,
      accountDataList,
      entityDataList,
      userMappingList,
      userLoading,
      searchClients,
      clients,
      errorText,
      accounts,
      searchAccountsByNumber,
      fetchDocumentListByFilters,
      totalRecordsCount,
      selectedAccount,
      selectedClient,
      visibilityData,
      selectedTab,
      visibilityLoading,
      documentFilters,
      setDocumentFilters,
      loading,
      uploadDialog,
      setUploadDialog
    ]
  )

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export default SearchContext
