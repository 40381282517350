import {
  IOConnectInitSettings,
  IOConnectProvider,
} from '@interopio/react-hooks'
import IOBrowser from '@interopio/browser'
import IODesktop from '@interopio/desktop'
import IOWorkspaces from '@interopio/workspaces-api'
// import Loader from '../components/loader/Loader'

interface Props {
  children: React.ReactNode
}

const InteropContextProvider = ({ children }: Props) => {
  const settings: IOConnectInitSettings = {
    desktop: {
      factory: IODesktop,
      config: {
        channels: true,
        layouts: true,
        appManager: true,
        libraries: [IOWorkspaces],
      },
    },
    browser: {
      config: { libraries: [IOWorkspaces] },
      factory: IOBrowser,
    },
  }
  return (
    <IOConnectProvider
      //   fallback={
      //     <Loader size={'104px'} loadingText={''} isFullPageLoader={true} />
      //   }
      settings={settings}
    >
      {children}
    </IOConnectProvider>
  )
}
export default InteropContextProvider
