import AddIcon from '../assets/icons/AddIcon.svg'
import Calendar from '../assets/icons/Calendar.svg'
import Cancel from '../assets/icons/Cancel.svg'
import CancelLarge from '../assets/icons/CancelLarge.svg'
import ChevronDown from '../assets/icons/ChevronDown.svg'
import BlueChevronDown from '../assets/icons/ChevronDownPrimary.svg'
import ChevronLeft from '../assets/icons/ChevronLeft.svg'
import ChevronRight from '../assets/icons/ChevronRight.svg'
import ChevronUp from '../assets/icons/ChevronUp.svg'
import BlueChevronUp from '../assets/icons/ChevronUpPrimary.svg'
import CloudDownload from '../assets/icons/CloudDownload.svg'
import Critical from '../assets/icons/Critical.svg'
import Default from '../assets/icons/Default.svg'
import DeleteIcon from '../assets/icons/DeleteIcon.svg'
import DownloadIcon from '../assets/icons/DownloadIcon.svg'
import DownloadWhiteIcon from '../assets/icons/DownloadWhiteIcon.svg'
import Exclamation from '../assets/icons/ExclamationWarning.svg'
import EyeIcon from '../assets/icons/Eye.svg'
import InfoCircle from '../assets/icons/InfoCircle.svg'
import LogOut from '../assets/icons/Logout.svg'
import PencilIcon from '../assets/icons/Pencil.svg'
import Plus from '../assets/icons/Plus.svg'
import Search from '../assets/icons/Search.svg'
import SearchGray from '../assets/icons/SearchGray.svg'
import Success from '../assets/icons/Success.svg'
import UploadFile from '../assets/icons/UploadFile.svg'
import Warning from '../assets/icons/Warning.svg'
import SRPLogo from '../assets/logos/SRPLogo.svg'
import Refresh from '../assets/icons/Refresh.svg'

export const Icons = {
  SRPLogo,
  Plus,
  ChevronUp,
  ChevronDown,
  Success,
  Default,
  Exclamation,
  Warning,
  Critical,
  Cancel,
  UploadFile,
  LogOut,
  ChevronLeft,
  ChevronRight,
  Calendar,
  CancelLarge,
  Refresh,
  Search,
  AddIcon,
  DeleteIcon,
  PencilIcon,
  EyeIcon,
  DownloadIcon,
  DownloadWhiteIcon,
  CloudDownload,
  InfoCircle
}
export const White_Icons = {}
export const WhiteIcons = {}

export const BlueIcons = {
  BlueChevronDown,
  BlueChevronUp,
}

export const NeutralIcons = {
  Cancel,
  SearchGray,
}
