import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'
import { CardProps } from './ITheming'

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.PrimaryText};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[6]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledButtonWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.Warning};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledButtonSuccess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.Success2};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledButtonDanger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.Danger};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${SIZES[0]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledButtonPrimarySmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.PrimaryText};
  border-radius: ${SIZES[0]};
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} ${SIZES[2]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[3]};
  }
`

export const StyledButtonPrimaryOutlined = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: 1px solid ${COLORS.UI.PrimaryText};
    border-radius: ${SIZES[0]};
    background: none;
    color: ${COLORS.UI.PrimaryText};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[2]};
    padding: ${SIZES[0]} ${SIZES[3]};
  }
`
export const StyledButtonTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    border-color: ${COLORS.Border.Transparent};
    background: none;
    color: ${COLORS.UI.NeutralText};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: ${SIZES[4]};
    padding: ${SIZES[0]} 0;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[3]};
    font-weight: ${TextWeight[0]};
  }
`

export const StyledButtonGrayTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.NeutralText};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} 0;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[3]};
    font-weight: ${TextWeight[3]};
  }
`
export const StyledButtonNeutralTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.Transparent};
  border-radius: 4px;
  cursor: pointer;
  button {
    border: ${COLORS.Border.Transparent};
    background: none;
    color: ${COLORS.UI.NeutralText};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.25rem 1rem;
    font-weight: 600;
    height: 2rem;
  }
`

export const StyledButtonLarge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.PrimaryText};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[4]};
    padding: ${SIZES[4]} ${SIZES[1]};
    font-size: ${TextSizes[3]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledButtonOutLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.White};
  border-radius: ${SIZES[0]};
  border: 1px solid ${COLORS.Border.PrimaryText};
  cursor: pointer;
  button {
    border: ${COLORS.Border.Transparent};
    background: none;
    color: ${COLORS.UI.PrimaryText};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[6]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.Neutral};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: ${COLORS.Border.Transparent};
    background: none;
    color: ${COLORS.UI.DisabledText};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[6]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonNeutralMed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.NeutralMedium};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: ${COLORS.Border.NeutralMedium};
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonAccent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  border: 1px solid ${COLORS.Border.Accent};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${COLORS.UI.Brand3};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonPrimaryText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.PrimaryText};
  border-radius: ${SIZES[0]};
  border: 1px solid ${COLORS.Border.PrimaryText};
  cursor: pointer;
  button {
    border: ${COLORS.Border.PrimaryText};
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonPrimaryTextExtraPadding = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.Background.PrimaryText};
  border-radius: ${SIZES[0]};
  border: 1px solid ${COLORS.Border.PrimaryText};
  cursor: pointer;
  button {
    border: ${COLORS.Border.PrimaryText};
    background: none;
    color: ${COLORS.UI.White};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const WDButtonNeutralMed = ({ children }: CardProps) => {
  return <StyledButtonNeutralMed>{children}</StyledButtonNeutralMed>
}

export const WDButtonIcon = ({ children }: CardProps) => {
  return <StyledButtonIcon>{children}</StyledButtonIcon>
}

export const WDButtonAccent = ({ children }: CardProps) => {
  return <StyledButtonAccent>{children}</StyledButtonAccent>
}

export const WDButton = ({ children }: CardProps) => {
  return <StyledButton>{children}</StyledButton>
}

export const WDButtonSuccess = ({ children }: CardProps) => {
  return <StyledButtonSuccess>{children}</StyledButtonSuccess>
}

export const WDButtonWarning = ({ children }: CardProps) => {
  return <StyledButtonWarning>{children}</StyledButtonWarning>
}

export const WDButtonDanger = ({ children }: CardProps) => {
  return <StyledButtonDanger>{children}</StyledButtonDanger>
}

export const WDButtonSmallPrimary = ({ children }: CardProps) => {
  return <StyledButtonPrimarySmall>{children}</StyledButtonPrimarySmall>
}

export const WDButtonPrimaryOutlined = ({ children }: CardProps) => {
  return <StyledButtonPrimaryOutlined>{children}</StyledButtonPrimaryOutlined>
}

export const WDButtonTransparent = ({ children }: CardProps) => {
  return <StyledButtonTransparent>{children}</StyledButtonTransparent>
}

export const WDButtonGrayTransparent = ({ children }: CardProps) => {
  return <StyledButtonGrayTransparent>{children}</StyledButtonGrayTransparent>
}

export const WDButtonNeutralTransparent = ({ children }: CardProps) => {
  return (
    <StyledButtonNeutralTransparent>{children}</StyledButtonNeutralTransparent>
  )
}

export const WDButtonLarge = ({ children }: CardProps) => {
  return <StyledButtonLarge>{children}</StyledButtonLarge>
}

export const WDButtonOutLine = ({ children }: CardProps) => {
  return <StyledButtonOutLine>{children}</StyledButtonOutLine>
}

export const WDButtonDisabled = ({ children }: CardProps) => {
  return <StyledButtonDisabled>{children}</StyledButtonDisabled>
}

export const WDButtonPrimaryText = ({ children }: CardProps) => {
  return <StyledButtonPrimaryText>{children}</StyledButtonPrimaryText>
}
