import { createContext } from 'react'
import {
  AiwContextInterface,
  AiwContextProps,
} from './AiwContextInterface'
import React from 'react'

export const AiwContext = createContext<AiwContextProps>({} as AiwContextProps)

const AiwContextProviderElectron = React.lazy(
  () => import('./AiwContextProviderElectron')
)
const AiwContextProvider: React.FC<AiwContextInterface> = (props) => {
  return <AiwContextProviderElectron {...props} />
}

export default AiwContextProvider
